import axios from "axios";

export default function apiAgent() {
  const baseUrl = "https://api.acuitydata.co.za/api/";
  const domain = "fatti.acuitydata.co.za";
  const username = "85847050-74cc-47ca-988b-d1a008d18556";
  const password = "d6da699b-c758-40d5-a621-e8effd39a819";
  const basicAuth = "Basic " + btoa(username + ":" + password);

  const reference = () => {
    let refURL = window.location.href;
    return refURL.indexOf("ref=") === -1 ? "" : refURL.split("ref=")[1];
  };

  const lookup = {
    make: () => {      
      const data = axios
        .get(`${baseUrl}lookup/make?reference=${reference()}`, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => { 
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },

    model: (curCopmpany) => {
      const data = axios
        .get(`${baseUrl}lookup/model/${curCopmpany}?reference=${reference()}`, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    year: (curCopmpany, curModel) => {
      const data = axios
        .get(
          `${baseUrl}lookup/year/${curCopmpany}/${curModel}?reference=${reference()}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    variant: (curCopmpany, curModel, curYear) => {
      const data = axios
        .get(
          `${baseUrl}lookup/variant/${curCopmpany}/${curModel}/${curYear}?reference=${reference()}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    register: (bodyData) => {
      bodyData.reference = reference();
      let data = axios
        .post(baseUrl + `register?reference=${reference()}`, bodyData, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("Some error occurred, Please try again");
        });

      return data;
    },
    report: (reportKey) => {
      const data = axios
        .get(
          `${baseUrl}report/${reportKey}?reference=${reference()}&domain=${domain}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again ")
        });
      return data;
    },
  };
  const userTheme = {
    clientInfo: (subDomain) => {
      let data = {
        domain: domain,
        client_logo: "./assets/Logo.png",
        theme_settings: {
          primary_color: "#185494",
          alternative_color: "rgb(178 174 171)",
          secondary_color: "#aaadb0",
        },
      };
      return data;
    },
  };
  return {
    lookup,
    userTheme,
    reference,
  };
}
