import React, { useState } from "react";

const CarMileage = ({
  setMasterIndex,
  carMileageValue,
  setcarMileageValue,
  themeData,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const onNextClick = () => {
    setMasterIndex(2);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const style = {
    color: isHovering ? "#185494" : "#185494",
  };
  return (
    <>
      <h2 style={{color:themeData.theme_settings.secondary_color}}>Ready to continue to the next step?</h2>
      <h4 className="my-2 text_blur">Mileage in KM (Optional)</h4>
      <input
        type="number"
        className="my-3 master_input"
        name=""
        id="input"
        max={100}
        value={carMileageValue}
        onChange={(e) => setcarMileageValue(e.target.value)}
      />
      <button
        className="next_btn my-4"
        onClick={() => onNextClick()}
        style={style}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        Next Step
      </button>
    </>
  );
};

export default CarMileage;
